import { ChatFeedItem, Feed, FeedItem, FeedWhatsAppConversations, HoolaMessage, MessageFeedItem } from "@/types/feed.types";
import { useWorkspaceStore } from "./useWorkspaceStore";
import { useSnackbarStore } from "./useSnackbarStore";
import { Expect } from "@/utils/Expect";
import workspaceAxios from '@/plugins/axios/workspace'
import { FeedMetadataDto } from "@/types/inbox.types";
import { AgentDto, AgentTeamDto, useAgentStore } from "./useAgentStore";
import { isSamePhoneNumber } from '@/utils/isSamePhoneNumber';
import { createMessageGroups, shouldSquash } from "@/services/inbox/createMessageGroups";
import { createMessageGroupForFeedItem } from "@/services/inbox/inbox.helpers";

interface State {
  feed: Feed | undefined;
  feedItems: FeedItem[];
  currentMessageOffset: number,
  totalFeedMessageCount: number,
  conversation: FeedWhatsAppConversations | undefined;
  feedMetadata: FeedMetadataDto | undefined;
  messageGroups: ChatFeedItem[];
}

export class MessageFilters {
  static DEFAULT_LIMIT = 25
}

export const useInboxCurrentFeedStore = defineStore('inbox-current-feed', {
  state: (): State => ({
    feed: undefined,
    feedItems: [],
    currentMessageOffset: 0,
    totalFeedMessageCount: 0,
    conversation: undefined,
    feedMetadata: undefined,
    messageGroups: [],
  }),
  getters: {
    mostRecentUserMessageDate(state): Date | undefined {
      let mostRecentCreatedAt: string | undefined;

      state.feedItems.forEach(item => {
        if (item.type !== 'MESSAGE') return;

        const isUserMessage = isSamePhoneNumber(`${item.data.sender.source}`, state.feed?.external_user_id);

        if (isUserMessage && (!mostRecentCreatedAt || item.data.created_at > mostRecentCreatedAt)) {
          mostRecentCreatedAt = item.data.created_at;
        }
      });

      if (!mostRecentCreatedAt) return;
    
      return new Date(mostRecentCreatedAt);
    },

    isLastUserMessageWithin24Hours(): boolean  {
      if (!this.mostRecentUserMessageDate) return false;

      return this.mostRecentUserMessageDate.getTime() > new Date().getTime() - (1 * 24 * 60 * 60 * 1000)
    },

    conversationExpiry(state): Date | undefined {
      if (!state.conversation) return;

      return new Date(state.conversation.expires_at);
    },

    recentMessageToOpenConvo(): boolean {
      if(!this.mostRecentUserMessageDate) return false;
      if(!this.conversationExpiry) return this.isLastUserMessageWithin24Hours;

      return this.isLastUserMessageWithin24Hours && this.mostRecentUserMessageDate.getTime() > this.conversationExpiry.getTime()
    },

    hoursToExpiry(): number {
      return this.conversationExpiry ? Math.abs(this.conversationExpiry.getTime() - (new Date()).getTime()) / 36e5 : -1
    },

    isOutsideOf24HourWindow(state): boolean {
      return this.hoursToExpiry <= 0 || state.feed?.channel !== 'whatsapp';
    },

    isWhatsAppDirectMessageEnabled(): boolean {
      return (!this.isOutsideOf24HourWindow && this.isLastUserMessageWithin24Hours) || this.recentMessageToOpenConvo;
    },

    assignedAgent(state): AgentDto | undefined {
      const agentStore = useAgentStore();
      
      if (!state.feedMetadata?.assignedAgents.length) return;

      const [agent] = agentStore.findByIds(state.feedMetadata.assignedAgents);

      return agent;
    },

    assignedTeam(state): AgentTeamDto | undefined {
      const agentStore = useAgentStore();

      if (!state.feedMetadata?.assignedTeams.length) return;

      const [team] = agentStore.findTeamByIds(state.feedMetadata.assignedTeams);

      return team;
    },
  },
  actions: {
    async loadFeed(feedId: string, keepCurrentOffset = false, conversation?: FeedWhatsAppConversations) {
      const workspaceStore = useWorkspaceStore();

      if(!feedId) return;

      this.currentMessageOffset = keepCurrentOffset ? this.currentMessageOffset : MessageFilters.DEFAULT_LIMIT;

      const path = await workspaceStore.buildPath(`feeds/${feedId}?limit=${this.currentMessageOffset}`);
      const { data } = await workspaceAxios.get(path, { withCredentials: true })

      if (keepCurrentOffset) {
        this.appendFeedItems(data.messaging.count, data.items.slice(0, data.items.length - this.feedItems.length));

        return;
      }

      const patch: Partial<State> = {
        feedItems: data.items,
        feed: data.feed,
        totalFeedMessageCount: data.messaging.count,
        messageGroups: createMessageGroups(data.items, data.feed),
      };

      if (conversation) {
        patch.conversation = conversation;
      }

      this.$patch(patch);
    },

    appendFeedItems(totalFeedMessageCount: number, feedItems: FeedItem[]) {
      this.$patch(state => {
        if (!state.feed) return;

        const messageGroups = createMessageGroups(feedItems, state.feed);

        state.feedItems.push(...feedItems);
        state.messageGroups.push(...messageGroups);
        state.totalFeedMessageCount = totalFeedMessageCount;
      });
    },

    async loadFeedMetadata(feedId: string) {
      const workspaceStore = useWorkspaceStore();
      const path = await workspaceStore.buildPath(`feeds/${feedId}/metadata`)
      const { data } = await workspaceAxios.get(path, { withCredentials: true })
      const { feedMetadata } = data as { feedMetadata: FeedMetadataDto }

      this.feedMetadata = feedMetadata;
    },

    async loadMoreMessages() {
      if (!this.feed) throw Error('Could not load more messages. Feed not found');

      this.currentMessageOffset += MessageFilters.DEFAULT_LIMIT

      await this.loadFeed(this.feed.id, true)
    },

    async saveNoteToCurrentFeed(message: string): Promise<string | undefined> {
      const workspaceStore = useWorkspaceStore();
      const snackbarStore = useSnackbarStore();

      if(!this.feed) return;

      const path = await workspaceStore.buildPath(`feeds/${this.feed.id}/notes`);
      const response = await workspaceAxios.post(
          path,
          {
            message,
            mentions: [],
            attachments: [],
          }, 
          { withCredentials: true }
        ).catch(e => {
          snackbarStore.addError(
            'Saving the note failed',
            e.message
          );
        });

      await this.loadFeed(this.feed.id);

      return Expect.string(response?.data?.id);
    },

    findMessageInCurrentFeedByExternalId(externalMessageId: string): HoolaMessage | undefined {
      const item = this.feedItems.find(item => {
        if (item.type !== 'MESSAGE') return;

        return item.data.external_message_id === externalMessageId
      });

      if (item?.type === 'MESSAGE') {
        return item.data;
      }
    },

    findExistingMessageFeedItem(searchFeedItem: FeedItem): MessageFeedItem | undefined {
      if (searchFeedItem.type !== 'MESSAGE') return;
      const message  = searchFeedItem.data;
      const item = this.feedItems.find(item => {
        if (item.type !== 'MESSAGE') return;

        return (message.external_message_id !== '' && item.data.external_message_id === message.external_message_id) || message.id === item.data.id;
      });

      if (item?.type === 'MESSAGE') {
        return item;
      }
    },

    addReaction(messageId: string,  reaction: string) {      
      this.feedItems.forEach((item) => {
        if (item.type !== 'MESSAGE' || item.data.external_message_id !== messageId) return;

        item.data.reaction = reaction;
      });
    },

    addFeedItem(feedItem: FeedItem) {
      this.$patch((state) => {
        if (!this.feed) return;

        this.feedItems.push(feedItem);

        const [lastMessageGroup] = state.messageGroups;

        if (lastMessageGroup.type === 'MESSAGE' && feedItem.type === 'MESSAGE') {
          const isSameGroup = lastMessageGroup.senderId === feedItem.data.sender.source.toString() &&  lastMessageGroup.initiator === feedItem.data.initiator;

          if (isSameGroup) {
            lastMessageGroup.messages.push(feedItem.data);

            return;
          }
        }

        if (lastMessageGroup.type === 'CONTACT_EVENT' && feedItem.type === 'CONTACT_EVENT') {
          if (shouldSquash(lastMessageGroup, feedItem)) {
            lastMessageGroup.squashed.push(feedItem);

            return;
          }
        }

        const newMessageGroup = createMessageGroupForFeedItem(this.feed, feedItem);

        if (newMessageGroup) {
          state.messageGroups.unshift(newMessageGroup);

          return;
        }
      });
    },
  },
});
