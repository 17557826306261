<script lang="ts" setup>
import { formatDateToMonthShort } from '@/@core/utils/formatters';

interface Props {
  date: Date;
}

const props = defineProps<Props>();

const datetimeFormatted = computed(() => {
  return formatDateToMonthShort(`${props.date}`, false);
}); 
</script>

<template>
  <div class="chat-feed-card__datetime">
    {{ datetimeFormatted }}
  </div>
</template>

<style scoped>
.chat-feed-card__datetime {
  color: var(--Neutral-Colors-600, #646464);
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  white-space: nowrap;
}
</style>
