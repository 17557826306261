import {
    isValidPhoneNumber,
    parsePhoneNumber,
} from 'libphonenumber-js'

export class PhoneNumberUtils {
    static validate(phoneNumber: string): boolean | string {
        if (!phoneNumber) return false;

        const cleanedPhoneNumber = `+${PhoneNumberUtils.cleanPhone(phoneNumber)}`;

        return isValidPhoneNumber(cleanedPhoneNumber) ? true : 'Must include country code'
    }

    static cleanPhone(phoneNumber: string): string {
        return phoneNumber
            .trim()
            .replaceAll(" ", "")
            .replaceAll('+', "")
            .replaceAll('.', '')
            .replaceAll('-', '');
    }

    static format(phoneNumber: string | undefined): string {
        if(!phoneNumber) return '';

        try {
            const number = parsePhoneNumber(phoneNumber)

            return number.formatInternational()
        } catch(e) {
            return phoneNumber;
        }
    }
}
