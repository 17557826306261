import { SnackbarMessageType, SnackbarMessage} from "@/types/snackbar.types";
import { Timer } from '@/utils/Timer';

interface State {
  items: SnackbarMessage[];
  timers: Partial<Record<string, Timer>>;
  isPaused: boolean;
}

export const useSnackbarStore = defineStore('snackbar', {
  state: (): State => ({
    items: [],
    timers: {},
    isPaused: false,
  }),
  actions: {
    add(title: string, message: string, type: SnackbarMessageType = 'info', opts?: { id?: string, persistent?: boolean }) {
      const id = opts?.id || Math.random().toString(36).slice(2);

      this.items.push({
        id,
        title, 
        message, 
        type,
        persistent: opts?.persistent || false
      });

      if(!opts?.persistent) {
        this.setTimer(id)
      }
    },
    update(id: string, title: string, message: string, type: SnackbarMessageType, opts?: { persistent?: boolean }) {
      const item = this.items.find(item => item.id === id)

      if(item) {
        item.title = title,
        item.message = message,
        item.type = type
        item.persistent = opts?.persistent || false

        if(!opts?.persistent) {
          this.setTimer(id)
        }
      }
    },
    setTimer(id: string) {
      const timer = new Timer(() => {
        this.remove(id);
      }, 5000);

      if (this.isPaused) {
        timer.pause();
      }

      this.timers[id] = timer;
    },
    addSuccess(title: string, message = '') {
      this.add(title, message, 'success');
    },
    addError(title: string, message = '') {
      this.add(title, message, 'error');
    },
    addWarning(title: string, message = '') {
      this.add(title, message, 'warning');
    },
    handleError(error: Error) {
      this.addError(error.message);
    },
    remove(id: string) {
      this.timers[id]?.clearTimeout();
      const index = this.items.findIndex(item => item.id === id);

      if (index < 0) return;

      this.items.splice(index, 1);

      if (!this.items.length) {
        this.timers = {};
      }
    },
    pauseAll() {
      this.isPaused = true;
      Object.values(this.timers).forEach(timer => timer?.pause());
    },
    resumeAll() {
      this.isPaused = false;
      Object.values(this.timers).forEach(timer => timer?.resume());
    }
  },
})
