export const isSamePhoneNumber = (a = 'a', b = 'b'): boolean => {
  if (a && !b) return false;
  if (a === b) return true;

  if (a.length > b.length) return a.includes(b);

  if (b.length > a.length) return b.includes(a);

  return false;
};
