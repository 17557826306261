<script setup lang="ts">
import { useTheme } from 'vuetify'
import { useThemeConfig } from '@core/composable/useThemeConfig'
import { hexToRgb } from '@layouts/utils'
import { useNotifications } from '@/utils/useNotifications'
import { useAgentPreferencesStore } from '@/stores/useAgentPreferencesStore';

const { syncInitialLoaderTheme, syncVuetifyThemeWithTheme: syncConfigThemeWithVuetifyTheme, isAppRtl } = useThemeConfig()

const { global } = useTheme()

const initNotifications = useNotifications();
const agentPreferencesStore = useAgentPreferencesStore();

const { isAnyNotificationEnabled } = storeToRefs(agentPreferencesStore);
const didInitNotifications = ref(false);
// ℹ️ Sync current theme with initial loader theme
syncInitialLoaderTheme()
syncConfigThemeWithVuetifyTheme()

watchEffect(async () => {
  if (isAnyNotificationEnabled.value && !didInitNotifications.value) {
    didInitNotifications.value = true;

    await initNotifications();
  }
});
</script>

<template>
  <VLocaleProvider :rtl="isAppRtl">
    <!-- ℹ️ This is required to set the background color of active nav link based on currently active global theme's primary -->
    <VApp :style="`--v-global-theme-primary: ${hexToRgb(global.current.value.colors.primary)}`">
      <RouterView />
      <SnackbarConnected />
    </VApp>
  </VLocaleProvider>
</template>
