<script lang="ts" setup>
import WhatsAppPng from '@/assets/logos/whatsapp/whatsapp.png';
import { FeedAction, useChatStore } from '@/stores/useChatStore';
import { Feed, FeedWhatsAppConversations } from '@/types/feed.types';
import { useAgentPreferencesStore } from '@/stores/useAgentPreferencesStore'
import ChatFeedCardBadges from './ChatFeedCardBadges.vue';
import ChatFeedCardContent from './ChatFeedCardContent.vue';
import ChatFeedCardActions from './ChatFeedCardActions.vue';
import ChatFeedCardDatetime from './ChatFeedCardDatetime.vue';

interface Props {
  feed: Feed
  isActive: boolean;
  isLoading: boolean;
  isSelected: boolean;
  disableSelection?: boolean;
  hasSelection: boolean;
}

interface Emits {
  (e: 'feedsUpdated', name: FeedAction): void
  (e: 'openFeed'): void
  (e: 'toggleSelect'): void
}

const props = defineProps<Props>();
const emits = defineEmits<Emits>();

const chatStore = useChatStore();
const agentPreferencesStore = useAgentPreferencesStore();

const { counts, conversations } = storeToRefs(chatStore);
const { pinnedFeeds } = storeToRefs(agentPreferencesStore);

const unrepliedCount = computed(() => {
  const counter = counts.value.find(count => count.feed_id === props.feed.id)

  return counter ? counter.unreplied_count : 0
});

const isPinned = computed((): boolean => {
  return pinnedFeeds.value.includes(props.feed.id);
});

const calculateHoursToExpiry = (convo: FeedWhatsAppConversations): number => {
  const currentDate = new Date()

  return convo ? Math.abs(new Date(convo.expires_at).getTime() - currentDate.getTime()) / 36e5 : -1
};

const isExpiringSoon = computed((): boolean => {
  const conversation = conversations.value.find((item) => item.feed_id === props.feed.id);
  const currentDate = new Date()

  if(conversation !== undefined && new Date(conversation.expires_at) > currentDate) {
    return calculateHoursToExpiry(conversation) <= 4
  }

  return false;
});

const handleClick = () => {
  if (props.hasSelection) {
    emits('toggleSelect');
  } else {
    emits('openFeed');
  }
}
</script>

<template>
  <div
    class="chat-feed-card pa-2 mb-2 d-flex ga-2 overflow-hidden cursor-pointer"
    :class="{
      'chat-feed-card--active': isActive && !hasSelection,
      'chat-feed-card--selected': isSelected,
      'chat-feed-card--selectable': !disableSelection,
      'chat-feed-card--loading': isLoading,
    }"
    @click="handleClick"
  >
    <DialogOverlay
      :contained="true"
      :is-dialog-visible="isLoading"
      :use-dots-loader="true"
      :opacity="0.9"
      :rgb="{ red: 255, green: 255, blue: 255 }"
      :top="47"
    />
    <div>
      <VImg
        v-if="!isSelected"
        class="chat-feed-card__icon"
        :src="WhatsAppPng"
        width="24"
      />
      <AppCheckboxToggle
        class="chat-feed-card__select"
        :model-value="isSelected"
        @click.stop
        @update:model-value="$emit('toggleSelect')"
      />
    </div>
    <ChatFeedCardContent :feed="feed" />
    <div class="chat-feed-card__details  d-flex flex-column justify-space-between align-end">
      <ChatFeedCardBadges
        :is-expiring-soon="isExpiringSoon"
        :is-pinned="isPinned"
        :unreplied-count="unrepliedCount"
      />
      <ChatFeedCardDatetime
        :date="feed.last_interaction_date"
      />
    </div>
    <ChatFeedCardActions
      :is-pinned="isPinned"
      :is-closed="feed.is_closed"
      :feed-id="feed.id"
    />
  </div>
</template>

<style scoped>
.chat-feed-card {
  border-radius: 11px;
  border: 0.6px solid var(--Stroke-Main, rgba(0, 0, 0, 0.10));
  max-width: 100%;
  position: relative;
}

.chat-feed-card--active {
  background: #f8fbff;
  box-shadow: 0px 2px 0px #086CD9, rgba(99, 99, 99, 0.2) 0px 2px 4px 0px;
}

.chat-feed-card--selected,
.chat-feed-card:hover {
  background: #F6F6F6;
}

.chat-feed-card :deep(.chat-feed-card-actions) {
  display: none;
}

.chat-feed-card:hover :deep(.chat-feed-card-actions) {
  display: flex;
}

.chat-feed-card:hover .chat-feed-card__details {
  visibility: hidden;
}

.chat-feed-card__select {
  visibility: hidden;
}

.chat-feed-card--selected .chat-feed-card__select,
.chat-feed-card--selectable:not(.chat-feed-card--loading):hover .chat-feed-card__select {
  visibility: visible;
}

.chat-feed-card--selectable:not(.chat-feed-card--loading):hover .chat-feed-card__icon {
  display: none;
}
</style>
